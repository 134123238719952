import Vue from 'vue'
import Router from 'vue-router'
import VueKinesis from 'vue-kinesis'

Vue.use(Router)

Vue.use(VueKinesis);

export default new Router({
    routes: [
        {
            path: '/',
            redirect: '/about',
        },
        {
            path: '/projects',
            name: 'projects',
            component: () => import('../pages/Projects')
        },
        {
            path: '/about',
            name: 'about',
            component: () => import('../pages/AboutMe')
        },
        {
            path: '/contact',
            name: 'contact',
            component: () => import('../pages/GetInTouch')
        },
        {
            path: '/timeline',
            name: 'timeline',
            component: () => import('../pages/Timeline')
        }
    ]
})
